import {
  type PartnerConfigMap,
  Partners,
} from '@service-initiation/widget-data-access-partners';

export const partnerInfoConfig: PartnerConfigMap = {
  [Partners.AMAZON]: {
    claimsLink:
      'https://protection.asurion.com/protection-plan/en-us/?cid=vn-rtm:amazon',
    phoneNumber: '866-551-5924',
    programName: 'Amazon protection plan',
  },
  [Partners.ATT]: {
    chatLink: 'https://www.asurion.com/att/chat/',
    claimsLink:
      'https://deviceprotection.phoneclaim.com/att/en-us/workflow/start',
    phoneNumber: '888-477-6832',
    programName: 'AT&T Protect Advantage',
  },
  [Partners.CRICKET]: {
    appLink: 'https://www.asurion.com/phone-insurance/app-center/',
    claimsLink:
      'https://deviceprotection.phoneclaim.com/cricket/en-us/workflow/start',
    programName: 'Cricket',
  },
  [Partners.HOME_PLUS]: {
    appLink: 'https://www.asurion.com/phone-insurance/app-center/',
    claimsLink:
      'https://my.asurion.com/my-devices/start-claim?mxclient=asurion&mxproducttype=home',
    phoneNumber: '844-529-2692',
    programName: 'Asurion Home+',
  },
  [Partners.NONE]: {
    findAStoreLink: 'https://www.asurion.com/store-locator/',
    programName: "I don't have a protection plan",
  },
  [Partners.VERIZON]: {
    chatLink: 'https://www.asurion.com/verizon/chat/',
    claimsLink:
      'https://deviceprotection.phoneclaim.com/verizon/en-us/workflow/start',
    phoneNumber: '866-686-2479',
    programName: 'Verizon Mobile Protect',
  },
};
