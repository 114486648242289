import {
  type InteractionNode,
  WidgetComponentType,
} from '@service-initiation/widget-data-access-interaction-model';
import { Partners } from '@service-initiation/widget-data-access-partners';
import { VariantOptions } from '@service-initiation/widget-ui-chat-bubble';

import { partnerInfoConfig } from './partnerInfoConfig';

export const asurionModel: InteractionNode[] = [
  {
    children: undefined,
    content: "Our experts can help you unlock your device's full potential.",
    id: 1,
    messageVariant: VariantOptions.ROUND,
    type: WidgetComponentType.MESSAGE,
  },
  {
    children: undefined,
    content: 'How can we help today?',
    id: 2,
    messageVariant: VariantOptions.FLAT,
    type: WidgetComponentType.MESSAGE,
  },
  {
    content: 'Start or track a claim',
    dataAnalyticsId: 'ClaimCTA',
    href: 'https://www.asurion.com/claims/?_ga=2.25132410.1754821433.1707764773-455488273.1701289949',
    iconSrc: 'programming/shield-check',
    id: 4,
    partner: undefined,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        children: undefined,
        content: "Let's connect you with live tech support.",
        id: 7,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Choose the carrier or retailer tied to your protection plan.',
        id: 8,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: [
          {
            children: undefined,
            content: `Great! ${
              partnerInfoConfig[Partners.VERIZON].programName
            } subscribers can get premium tech help anytime, anywhere.`,
            id: 15,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            children: undefined,
            content: 'Choose how you would like to get help.',
            id: 16,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },

          {
            children: undefined,
            content: 'Start or track a claim',
            dataAnalyticsId: 'ClaimCTA',
            href: partnerInfoConfig[Partners.VERIZON].claimsLink,
            iconSrc: 'programming/shield-check',
            id: 19,
            partner: Partners.VERIZON,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: `Call ${partnerInfoConfig[Partners.VERIZON].phoneNumber}`,
            dataAnalyticsId: 'CallCTA',
            iconSrc: 'phone',
            id: 17,
            partner: Partners.VERIZON,
            phone: partnerInfoConfig[Partners.VERIZON].phoneNumber,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ChatCTA',
            href: partnerInfoConfig[Partners.VERIZON].chatLink,
            iconSrc: 'chat-bubble-circle',
            id: 18,
            partner: Partners.VERIZON,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'verizon',
        id: 9,
        partner: Partners.VERIZON,
        type: WidgetComponentType.PARTNER,
      },
      {
        children: [
          {
            children: undefined,
            content: `Great! ${
              partnerInfoConfig[Partners.ATT].programName
            } subscribers can get premium tech help anytime, anywhere.`,
            id: 20,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            children: undefined,
            content: 'Choose how you would like to get help.',
            id: 21,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },

          {
            children: undefined,
            content: 'Start or track a claim',
            dataAnalyticsId: 'ClaimCTA',
            href: partnerInfoConfig[Partners.ATT].claimsLink,
            iconSrc: 'programming/shield-check',
            id: 24,
            partner: Partners.ATT,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: `Call ${partnerInfoConfig[Partners.VERIZON].phoneNumber}`,
            dataAnalyticsId: 'CallCTA',
            iconSrc: 'phone',
            id: 22,
            partner: Partners.ATT,
            phone: partnerInfoConfig[Partners.ATT].phoneNumber,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ChatCTA',
            href: partnerInfoConfig[Partners.ATT].chatLink,
            iconSrc: 'chat-bubble-circle',
            id: 23,
            partner: Partners.ATT,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'att',
        id: 10,
        partner: Partners.ATT,
        type: WidgetComponentType.PARTNER,
      },
      {
        children: [
          {
            children: undefined,
            content: `Great! ${
              partnerInfoConfig[Partners.CRICKET].programName
            } subscribers can get premium tech help anytime, anywhere.`,
            id: 25,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            children: undefined,
            content: `Download the app`,
            href: partnerInfoConfig[Partners.CRICKET].appLink,
            iconSrc: 'resize-move/expand-4',
            id: 26,
            partner: Partners.CRICKET,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: 'Chat with an expert',
            dataAnalyticsId: 'ChatCTA',
            href: partnerInfoConfig[Partners.VERIZON].chatLink,
            iconSrc: 'chat-bubble-circle',
            id: 27,
            partner: Partners.VERIZON,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'cricket',
        id: 11,
        partner: Partners.CRICKET,
        type: WidgetComponentType.PARTNER,
      },
      {
        children: [
          {
            children: undefined,
            content: `Great! ${
              partnerInfoConfig[Partners.AMAZON].programName
            } subscribers can get premium tech help anytime, anywhere.`,
            id: 28,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },

          {
            children: undefined,
            content: 'Start or track a claim',
            dataAnalyticsId: 'ClaimCTA',
            href: partnerInfoConfig[Partners.AMAZON].claimsLink,
            iconSrc: 'programming/shield-check',
            id: 30,
            partner: Partners.AMAZON,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: `Call ${partnerInfoConfig[Partners.AMAZON].phoneNumber}`,
            dataAnalyticsId: 'CallCTA',
            iconSrc: 'phone',
            id: 29,
            partner: Partners.AMAZON,
            phone: partnerInfoConfig[Partners.AMAZON].phoneNumber,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'amazon',
        id: 12,
        partner: Partners.AMAZON,
        type: WidgetComponentType.PARTNER,
      },
      {
        children: [
          {
            children: undefined,
            content: `Great! ${
              partnerInfoConfig[Partners.HOME_PLUS].programName
            } subscribers can get premium tech help anytime, anywhere.`,
            id: 31,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            children: undefined,
            content: 'Choose how you would like to get help.',
            id: 32,
            messageVariant: VariantOptions.FLAT,
            type: WidgetComponentType.MESSAGE,
          },

          {
            children: undefined,
            content: 'Start or track a claim',
            dataAnalyticsId: 'ClaimCTA',
            href: partnerInfoConfig[Partners.HOME_PLUS].claimsLink,
            iconSrc: 'programming/shield-check',
            id: 35,
            partner: Partners.HOME_PLUS,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: `Call ${
              partnerInfoConfig[Partners.HOME_PLUS].phoneNumber
            }`,
            dataAnalyticsId: 'CallCTA',
            iconSrc: 'phone',
            id: 33,
            partner: Partners.HOME_PLUS,
            phone: partnerInfoConfig[Partners.HOME_PLUS].phoneNumber,
            type: WidgetComponentType.SERVICE,
          },
          {
            children: undefined,
            content: 'Download the app',
            href: partnerInfoConfig[Partners.HOME_PLUS].appLink,
            iconSrc: 'resize-move/expand-4',
            id: 34,
            partner: Partners.HOME_PLUS,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'home-plus',
        id: 13,
        partner: Partners.HOME_PLUS,
        type: WidgetComponentType.PARTNER,
      },
      {
        children: [
          {
            children: undefined,
            content:
              'No problem! You can get expert help and repairs for any of your devices at one of our many uBreakiFix locations.',
            id: 36,
            messageVariant: VariantOptions.ROUND,
            type: WidgetComponentType.MESSAGE,
          },
          {
            children: undefined,
            content: `Find a store`,
            href: partnerInfoConfig[Partners.NONE].findAStoreLink,
            iconSrc: 'shopping/store',
            id: 37,
            partner: Partners.NONE,
            type: WidgetComponentType.SERVICE,
          },
        ],
        content: 'none',
        id: 14,
        partner: Partners.NONE,
        type: WidgetComponentType.PARTNER,
      },
    ],
    content: 'Get tech support',
    iconSrc: 'custom/headset',
    id: 3,
    partner: undefined,
    type: WidgetComponentType.SERVICE,
  },
  {
    content: 'Repair a device',
    href: 'https://my.asurion.com/tech-care/repairs',
    iconSrc: 'wrench',
    id: 5,
    partner: undefined,
    type: WidgetComponentType.SERVICE,
  },
  {
    content: 'Find a store',
    href: 'https://www.asurion.com/store-locator/',
    iconSrc: 'shopping/store',
    id: 6,
    partner: undefined,
    type: WidgetComponentType.SERVICE,
  },
];
